<template>
  <v-sheet class="" id="customer">
    <v-row>
      <v-col
        style="position:sticky;top:4.5rem;z-index:90;!important;padding:2px!important"
        class="d-flex white grey lighten-3"
      >
        <v-col md="6" class="my-auto">
          <h1 class="custom-header-blue-text m-0">{{ title }}</h1>
        </v-col>
        <v-col md="6" class="text-right">
          <v-btn
            :disabled="pageLoading || !formValid"
            :loading="pageLoading"
            class="white--text mr-2"
            depressed
            color="blue darken-4"
            tile
            @click="confirmTestBroadcast"
          >
            Test
          </v-btn>
          <v-btn
            :disabled="pageLoading || !formValid"
            :loading="pageLoading"
            class="white--text mr-2"
            depressed
            color="blue darken-4"
            tile
            @click="blastBroadcast"
          >
            Blast
          </v-btn>
          <v-btn
            :disabled="pageLoading || !formValid"
            :loading="pageLoading"
            class="white--text mr-2"
            depressed
            color="blue darken-4"
            tile
            @click="saveBroadcast"
          >
            Save
          </v-btn>
          <v-btn class="ml-2" :disabled="pageLoading" depressed tile v-on:click="goBack()">
            Cancel
          </v-btn>
        </v-col>
      </v-col>
      <v-col md="12">
        <v-form
          ref="broadcastForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="updateOrCreate"
        >
          <v-container fluid>
            <v-row>
              <v-col md="12" class="d-flex justify-end template" style="padding: 0px !important">
                <v-btn
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  class="white--text mt-1 ml-4"
                  x-small
                  color="blue darken-4"
                  text
                  tile
                  v-on:click="generateSegmentDialog = true"
                >
                  <span style="font-size: 10px; font-weight: 700" class="text-underline"
                    >Create Filter</span
                  >
                </v-btn>
              </v-col>
              <v-col md="3" class="my-auto py-0">
                <label for="name" class="btx-label mt-">Name </label>
              </v-col>
              <v-col md="3" class="py-0">
                <TextInput
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  :rules="[vrules.required(broadcast.name, 'Broadcast Name')]"
                  :class="{ required: !broadcast.name }"
                  id="name"
                  v-model="broadcast.name"
                  placeholder="Broadcast name"
                ></TextInput>
              </v-col>
              <v-col md="3" class="my-auto py-0 text-right">
                <label for="segment" class="btx-label mt-2 text-right">Segment</label>
              </v-col>
              <v-col md="3" class="py-0">
                <SelectInput
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  :items.sync="segmentList"
                  :rules="[vrules.required(broadcast.segment, 'Segment Name')]"
                  :class="{ required: !broadcast.segment }"
                  id="segment"
                  item-text="name"
                  item-value="id"
                  v-model="broadcast.segment"
                  placeholder="Select Segment"
                ></SelectInput>
              </v-col>
              <v-col md="3" class="my-auto py-0">
                <label for="segment" class="btx-label mt-2">Type</label>
              </v-col>

              <v-col md="9" class="py-0 custom" style="padding: 0px !important">
                <!-- <v-btn-toggle mandatory color="blue darken-4" v-model="broadcast.type">
                  <v-btn
                    :color="broadcast.type == 'sms' ? 'blue darken-1' : ''"
                    :outlined="broadcast.type == 'sms' ? true : false"
                    value="sms"
                    disabled
                    x-large
                  >
                    <span
                      class="font-level-4-bold"
                      :class="broadcast.type == 'sms' ? 'blue--text text--darken-1' : 'black--text'"
                    >
                      SMS</span
                    >
                    <v-icon x-large color="blue darken-1" class="ml-2">mdi-message-outline</v-icon>
                  </v-btn>
                  <v-btn
                    :color="broadcast.type == 'whatsapp' ? 'green darken-4' : ''"
                    class="mx-2"
                    x-large
                    :outlined="broadcast.type == 'whatsapp' ? true : false"
                    value="whatsapp"
                  >
                    <span
                      class="font-level-4-bold"
                      :class="broadcast.type == 'whatsapp' ? 'green--text' : 'black--text'"
                    >
                      Whatsapp</span
                    >

                    <v-icon color="green" x-large class="ml-2">mdi-whatsapp</v-icon>
                  </v-btn>
                  <v-btn
                    x-large
                    :color="broadcast.type == 'email' ? 'red darken-1' : ''"
                    :outlined="broadcast.type == 'email' ? true : false"
                    class="font-level-4-bold"
                    value="email"
                    disabled
                  >
                    <span
                      class="font-level-4-bold"
                      :class="
                        broadcast.type == 'email' ? 'red--text text--darken-1' : 'black--text'
                      "
                    >
                      Email</span
                    >
                    <v-icon x-large color="red darken-1" class="ml-2">mdi-email-outline</v-icon>
                  </v-btn>
                </v-btn-toggle> -->
                <v-radio-group
                  id="template_type"
                  hide-details
                  dense
                  mandatory
                  row
                  style="width: 100%; padding: 0px 8px"
                  class="d-flex"
                  v-model="broadcast.type"
                >
                  {{ broadcast.type }}
                  <v-row class="mx-4">
                    <v-col md="3">
                      <v-radio
                        value="sms"
                        class="font-level-2-bold px-4 p-2 mr-0 d-flex justify-space-between"
                        label="SMS"
                        :class="broadcast.type == 'sms' ? 'orange lighten-4' : 'grey lighten-5'"
                        :color="broadcast.type == 'sms' ? 'orange ' : ''"
                        text-color="white"
                        icon="mdi-message-outline"
                        x-large
                        style="background-color: #f2d8b8; width: auto"
                        :style="[
                          broadcast.type == 'sms'
                            ? { border: ' 1px solid #e08310 !important' }
                            : { border: '1px solid #ECEFF1 !important' },
                        ]"
                      >
                        <template v-slot:label>
                          <div
                            class="d-flex justify-space-between align-center"
                            style="width: 100%"
                          >
                            <span class="font-level-3-bold"> SMS </span>
                            <v-icon
                              x-large
                              :color="
                                broadcast.type == 'sms' ? 'orange darken-2' : 'grey lighten-1'
                              "
                              >mdi-message</v-icon
                            >
                          </div>
                        </template>
                      </v-radio>
                    </v-col>
                    <v-col md="3" class="custom-radio">
                      <v-radio
                        value="whatsapp"
                        class="font-level-2-bold px-4 p-2 mr-0 d-flex justify-space-between"
                        label="whatsapp"
                        :class="
                          broadcast.type == 'whatsapp' ? 'orange lighten-4' : 'grey lighten-5'
                        "
                        :color="broadcast.type == 'whatsapp' ? 'orange ' : ''"
                        text-color="white"
                        icon="mdi-message-outline"
                        x-large
                        style="background-color: #f2d8b8; margin-right: 0px !important"
                        :style="[
                          broadcast.type == 'whatsapp'
                            ? { border: ' 1px solid #e08310 !important' }
                            : { border: '1px solid #ECEFF1 !important' },
                        ]"
                      >
                        <template v-slot:label>
                          <div
                            class="d-flex justify-space-between align-center"
                            style="width: 100%"
                          >
                            <span class="font-level-3-bold"> Whatsapp </span>
                            <v-icon
                              x-large
                              :color="
                                broadcast.type == 'whatsapp' ? 'orange darken-2' : 'grey lighten-1'
                              "
                              >mdi-whatsapp</v-icon
                            >
                          </div>
                        </template>
                      </v-radio>
                    </v-col>
                    <v-col md="3">
                      <v-radio
                        value="email"
                        class="font-level-2-bold px-4 p-2 mr-0 d-flex justify-space-between"
                        label="email"
                        :class="broadcast.type == 'email' ? 'orange lighten-4' : 'grey lighten-5'"
                        :color="broadcast.type == 'email' ? 'orange ' : ''"
                        text-color="white"
                        icon="mdi-message-outline"
                        x-large
                        style="background-color: #f2d8b8"
                        :style="[
                          broadcast.type == 'email'
                            ? { border: ' 1px solid #e08310 !important' }
                            : { border: '1px solid #ECEFF1 !important' },
                        ]"
                      >
                        <template v-slot:label>
                          <div
                            class="d-flex justify-space-between align-center"
                            style="width: 100%"
                          >
                            <span class="font-level-3-bold"> email </span>
                            <v-icon
                              x-large
                              :color="
                                broadcast.type == 'email' ? 'orange darken-2' : 'grey lighten-1'
                              "
                              >mdi-email</v-icon
                            >
                          </div>
                        </template>
                      </v-radio>
                    </v-col>
                    <v-col md="3">
                      <v-radio
                        value="notification"
                        class="font-level-2-bold px-4 p-2 mr-0 d-flex justify-space-between"
                        label="notification"
                        :class="
                          broadcast.type == 'notification' ? 'orange lighten-4' : 'grey lighten-5'
                        "
                        :color="broadcast.type == 'notification' ? 'orange ' : ''"
                        text-color="white"
                        icon="mdi-message-outline"
                        x-large
                        style="background-color: #f2d8b8"
                        :style="[
                          broadcast.type == 'notification'
                            ? { border: ' 1px solid #e08310 !important' }
                            : { border: '1px solid #ECEFF1 !important' },
                        ]"
                      >
                        <template v-slot:label>
                          <div
                            class="d-flex justify-space-between align-center"
                            style="width: 100%"
                          >
                            <span class="font-level-3-bold"> notification </span>
                            <v-icon
                              x-large
                              :color="
                                broadcast.type == 'notification'
                                  ? 'orange darken-2'
                                  : 'grey lighten-1'
                              "
                              >mdi-bell</v-icon
                            >
                          </div>
                        </template>
                      </v-radio>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-col>
              <v-col md="3" class="my-auto py-0">
                <label for="category" class="btx-label mt-2"> Category </label>
              </v-col>
              <v-col md="9" class="py-0">
                <SelectInput
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  :items.sync="categoryList"
                  :rules="[vrules.required(broadcast.category, 'Category Name')]"
                  :class="{ required: !broadcast.category }"
                  id="category"
                  @change="filterTemplateList()"
                  item-text="name"
                  item-value="slug"
                  v-model="broadcast.category"
                  placeholder="Select Category"
                ></SelectInput>
              </v-col>

              <v-col md="3" class="my-auto py-0">
                <label for="segment" class="btx-label mt-2">Schedule Type</label>
              </v-col>

              <v-col
                :md="broadcast.category == 'birthday' ? 3 : 9"
                class=""
                style="padding: 4px 12px !important"
              >
                <v-chip-group mandatory v-model="broadcast.schedule_type" row>
                  <v-chip
                    :class="{
                      'blue darken-3 white--text': broadcast.schedule_type == 'now',
                    }"
                    label
                    value="now"
                    >Now</v-chip
                  >
                  <v-chip
                    :class="{
                      'blue darken-3 white--text': broadcast.schedule_type == 'later',
                    }"
                    label
                    class="ml-4 p-2"
                    value="later"
                    >Later</v-chip
                  >
                </v-chip-group>

                <!-- <v-radio-group mandatory small v-model="broadcast.schedule_type" row>
                  <v-radio label="Now" value="now"></v-radio>
                  <v-radio label="Later" value="later"></v-radio>
                </v-radio-group> -->
              </v-col>
              <template v-if="broadcast.category == 'birthday'">
                <v-col md="3" class="my-auto py-0 text-right">
                  <label for="segment" class="btx-label mt-2">Days Before</label>
                </v-col>
                <v-col md="3" class="my-auto py-0">
                  <NumberInput
                    hide-details
                    :loading="pageLoading"
                    :disabled="pageLoading"
                    class="mr-1 mt-3"
                    placeholder="Days Before"
                    v-model.number="broadcast.day_before"
                  ></NumberInput>
                </v-col>
              </template>
              <template v-if="broadcast?.category == 'promotion'">
                <v-col md="3" class="my-auto py-0">
                  <label for="recurrence-type" class="btx-label mt-2">Recurrence Type</label>
                </v-col>
                <v-col md="3" class="py-0 custom">
                  <SelectInput
                    hide-details
                    :items="remindertypeList"
                    :disabled="pageLoading"
                    item-text="text"
                    item-value="value"
                    @change="reminderTypeChange"
                    :loading="pageLoading"
                    id="recurrence-type"
                    placeholder="Recurrence Type"
                    v-model="broadcast.recurrence_type"
                  >
                  </SelectInput>
                </v-col>
                <template v-if="broadcast.recurrence_type != 1">
                  <v-col md="3" class="my-auto py-0 text-right">
                    <label for="frequency" class="btx-label mt-2">Frequency</label>
                  </v-col>
                  <v-col md="3" class="py-0">
                    <TextInput
                      type="number"
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      id="frequency"
                      @keyup="frequencyChange"
                      placeholder="Frequency"
                      v-model.number="broadcast.frequency"
                    ></TextInput>
                  </v-col>
                </template>
                <template v-else>
                  <v-col md="6"> </v-col>
                </template>
                <v-col md="3" class="my-auto py-0">
                  <label for="recurrence_start_date" class="btx-label mt-4"
                    >Recurrence Start Date</label
                  >
                </v-col>
                <v-col md="3" class="py-0">
                  <DatePicker
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    @change="reminderTypeChange"
                    id="recurrence_start_date"
                    v-model="broadcast.recurrence_start_date"
                  >
                  </DatePicker>
                </v-col>
              </template>

              <template v-if="broadcast?.category == 'promotion' && broadcast.recurrence_type != 1">
                <v-col md="3" class="my-auto py-0 text-right">
                  <label for="recurrence_end_date" class="btx-label mt-4"
                    >Recurrence End Date</label
                  >
                </v-col>
                <v-col md="3" class="py-0">
                  <DatePicker
                    hide-details
                    disabled
                    :loading="pageLoading"
                    id="recurrence_end_date"
                    v-model="broadcast.recurrence_end_date"
                  >
                  </DatePicker>
                </v-col>
              </template>
              <template
                v-else-if="
                  broadcast?.category == 'promotion' &&
                  broadcast?.category != 'promotion' &&
                  broadcast.recurrence_type == 1
                "
              >
                <v-col md="6"></v-col>
              </template>
              <template v-if="broadcast.schedule_type == 'later'">
                <v-col md="12" class="d-flex" style="padding: 0 !important">
                  <v-col md="3" class="my-auto py-0">
                    <label for="segment" class="btx-label mt-2">Schedule Date</label>
                  </v-col>
                  <v-col md="3" class="my-auto py-0">
                    <DatePicker
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      id="date"
                      class="required"
                      placeholder="Date"
                      :rules="[vrules.required(broadcast.schedule_date, 'Schedule Date Time')]"
                      :class="{ required: !broadcast.schedule_date }"
                      v-model="broadcast.schedule_date"
                    ></DatePicker>
                  </v-col>
                  <v-col md="3" class="my-auto py-0 mt-3 text-right">
                    <label for="segment" class="btx-label">Schedule Time</label>
                  </v-col>
                  <v-col md="3" class="my-auto py-0">
                    <v-menu
                      ref="menu"
                      v-model="menu2"
                      :close-on-content-click="false"
                      :return-value.sync="broadcast.time"
                      offset-y
                      class="mt-3"
                      max-width="320px"
                      min-width="320px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="broadcast.time"
                          hide-details
                          outlined
                          prepend-inner-icon="mdi-clock-time-four-outline"
                          readonly
                          placeholder="Select Time"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>

                      <v-time-picker
                        v-if="menu2"
                        v-model="broadcast.time"
                        @click:minute="$refs.menu.save(broadcast.time)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-col>
              </template>

              <v-col md="12" class="mt-8 d-flex justify-space-between">
                <v-flex
                  md4
                  class="custom-box-shadow p-4 grey lighten-5 template"
                  style="max-height: 650px; overflow: scroll"
                >
                  <v-list class="grey lighten-5" dense>
                    <span class="btx-label">Select Template</span>
                    <template v-if="filterTemplateLists.length">
                      <v-list-item-group
                        v-model="broadcast.selectedTemplate"
                        color="primary"
                        class="mt-3"
                      >
                        <v-list-item
                          class="py-2 px-4"
                          @click="templateChange(item.template_name)"
                          v-for="(item, index) in filterTemplateLists"
                          :key="index"
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              >{{ item.template_name }}
                              <!-- {{ item.template_type }}
                            {{ item.template_category }} -->
                              <div style="font-size: 10px !important" class="mt-1">
                                <span :class="getStatusColor(item.status)"> {{ item.status }}</span>
                              </div>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </template>
                    <template v-else>
                      <div class="font-level-4-bold mt-6">No Template Available for selection</div>
                    </template>
                  </v-list>
                </v-flex>
                <v-flex
                  md5
                  class="custom-box-shadow p-4 mx-6"
                  style="max-height: 650px; overflow: scroll"
                >
                  <template v-if="selectedTemplate?.frontend?.is_template_has_params">
                    <div
                      v-if="
                        selectedTemplate?.frontend?.header?.parameters &&
                        selectedTemplate?.frontend?.header?.parameters.length
                      "
                    >
                      <span class="btx-label mt-1">Header </span>
                      <div
                        class="d-flex"
                        v-for="(item, index) of selectedTemplate?.frontend?.header?.parameters"
                        :key="index"
                      >
                        <v-flex md3 class="my-auto py-0">
                          <label for="">
                            <template>&#123;&#123;</template>{{ index + 1
                            }}<template>&#125;&#125;</template>
                          </label>
                        </v-flex>
                        <v-flex md9 class="py-0">
                          <TextInput
                            hide-details
                            :rules="[vrules.required(item.value, `Header Parameter ${index + 1}`)]"
                            :class="{ required: !item.value }"
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            :placeholder="`Header Parameter ${index + 1}`"
                            id="headerPara"
                            v-model="item.value"
                          ></TextInput>
                        </v-flex>
                      </div>
                    </div>
                    <div v-if="selectedTemplate?.frontend?.is_file_header">
                      <span class="btx-label mt-1"
                        >Header File
                        <label for="file" class="mt-1" style="text-transform: capitalize">
                          ({{ selectedTemplate?.header_type }})
                        </label>
                      </span>
                      <!-- <v-flex md12 class="my-auto py-0">
                        <label for="file" class="mt-1" style="text-transform: capitalize">
                          {{ selectedTemplate?.header_type }}
                        </label>
                      </v-flex> -->
                      <v-flex md12 class="py-0">
                        <FileUpload
                          @input="inputFileImage"
                          id="file"
                          @clearFile="fileRemove"
                          directionColumn
                          :accept="
                            selectedTemplate?.header_type == 'image' ? 'image' : 'application'
                          "
                          :allowAddMore="false"
                          v-model="broadcast.header_image"
                        ></FileUpload>
                      </v-flex>
                    </div>
                    <div v-if="selectedTemplate?.frontend?.body?.parameters.length" class="mt-4">
                      <span class="btx-label mt-1">Body</span>
                      <div
                        class="d-flex"
                        v-for="(item, bodyIndex) of selectedTemplate?.frontend?.body?.parameters"
                        :key="bodyIndex"
                      >
                        <v-flex md3 class="my-auto py-0">
                          <label for="">
                            <template>&#123;&#123;</template>{{ bodyIndex + 1
                            }}<template>&#125;&#125;</template>
                          </label>
                        </v-flex>
                        <v-flex md9 class="py-0">
                          <TextInput
                            hide-details
                            :disabled="pageLoading"
                            :rules="[
                              vrules.required(item.value, `Body Parameter ${bodyIndex + 1}`),
                            ]"
                            :class="{ required: !item.value }"
                            :loading="pageLoading"
                            :placeholder="`Body Parameter ${bodyIndex + 1}`"
                            id="bodyPara"
                            v-model="item.value"
                          ></TextInput>
                        </v-flex>
                      </div>
                    </div>

                    <template v-for="(button, index) of selectedTemplate?.frontend?.buttons">
                      <div class="mt-4" v-if="button?.parameters.length" :key="index + 45100">
                        <div class="d-flex flex-column">
                          <div class="btx-label mt-1">{{ button?.text }}</div>
                          <div>{{ dynamicButtonUrl(index + 1) }}</div>
                        </div>
                        <template v-if="button?.parameters.length">
                          <div
                            class="d-flex"
                            v-for="(item, paraIndex) of button?.parameters"
                            :key="paraIndex + 4551"
                          >
                            <v-flex md3 class="my-auto py-0">
                              <label>
                                <template>&#123;&#123;</template>{{ index + 1
                                }}<template>&#125;&#125;</template>
                              </label>
                            </v-flex>
                            <v-flex md9 class="py-0">
                              <TextInput
                                hide-details
                                :disabled="pageLoading"
                                :placeholder="`Url Parameter ${paraIndex + 1}`"
                                :rules="[vrules.required(item.value, `URL Parameter ${paraIndex}`)]"
                                :class="{ required: !item.value }"
                                :loading="pageLoading"
                                id="bodyPara"
                                v-model="item.value"
                              ></TextInput>
                            </v-flex>
                          </div>
                        </template>
                      </div>
                    </template>
                  </template>
                  <template v-else>
                    <div class="d-flex justify-center align-center grey--text">
                      <span class="font-level-4-bold">No Parameter Used in Selected Template</span>
                    </div>
                  </template>
                </v-flex>
                <v-flex md3>
                  <MobileFrame>
                    <template v-slot:content>
                      <div style="border-radius: 1rem; background-color: white">
                        <div id="whatsapp-chat">
                          <div class="start-chat" v-if="broadcast.type !== 'notification'">
                            <div
                              pattern="https://elfsight.com/assets/chats/patterns/whatsapp.png"
                              class="WhatsappChat__Component-sc-1wqac52-0 whatsapp-chat-body"
                            >
                              <div
                                class="d-flex flex-column justify-space-between"
                                style="height: 100%"
                              >
                                <div class="WhatsappChat__MessageContainer-sc-1wqac52-1 dAbFpq">
                                  <div
                                    style="opacity: 1; margin-left: 1px"
                                    class="WhatsappChat__Message-sc-1wqac52-4 kAZgZq"
                                  >
                                    <div class="WhatsappChat__Text-sc-1wqac52-2 iSpIQi">
                                      {{ matchingHeader() }}
                                    </div>
                                    <div
                                      class="font-level-1-bold"
                                      v-if="
                                        header_image_url &&
                                        this.selectedTemplate.header_type == 'image'
                                      "
                                    >
                                      <v-img
                                        v-if="
                                          header_image_url &&
                                          this.selectedTemplate.header_type == 'image'
                                        "
                                        style="height: 150px; width: 100%; border-radius: 0.2rem"
                                        :src="header_image_url"
                                      ></v-img>
                                    </div>
                                    <span style="display: none"> {{ header_image_url }}</span>

                                    <div
                                      class="font-level-1-bold grey lighten-4 p-2"
                                      style="font-weight: 600; border-radius: 0.2rem"
                                      v-if="
                                        header_image_url &&
                                        this.selectedTemplate.header_type == 'document'
                                      "
                                    >
                                      <v-icon color="red">mdi-file-pdf-box</v-icon>
                                      <span style="font-weight: 500" class="font-level-2-bold ml-1">
                                        {{ template.file[0].name }}
                                      </span>
                                      <!-- <v-img
                               
                                style="height: 150px; width: 100%; border-radius: 0.7rem"
                                :src="header_image_url"
                              ></v-img> -->
                                    </div>
                                    <div
                                      class="font-level-1-bold"
                                      v-if="header_image_url && selectedTemplate?.is_file_header"
                                    >
                                      <v-img
                                        v-if="header_image_url"
                                        style="height: 150px; width: 100%; border-radius: 0.2rem"
                                        :src="header_image_url"
                                      ></v-img>
                                    </div>
                                    <!-- <span style="display: none"> {{ header_image_url }}</span> -->

                                    <!-- <div
                              class="font-level-1-bold grey lighten-4 p-2"
                              style="font-weight: 600; border-radius: 0.2rem"
                              v-if="upload_file_url && template.header_type == 'document'"
                            >
                              <v-icon color="red">mdi-file-pdf-box</v-icon>
                              <span style="font-weight: 500" class="font-level-2-bold ml-1">
                                {{ template.file[0].name }}
                              </span>
                            </div> -->
                                    <div class="mt-1">
                                      {{ matchingBody() }}
                                    </div>
                                    <div class="mt-2">
                                      {{ selectedTemplate?.frontend?.footer }}
                                      <!-- {{button_text_values}} -->
                                    </div>

                                    <div class="WhatsappChat__Time-sc-1wqac52-5 cqCDVm">Now</div>
                                  </div>
                                  <template>
                                    <div
                                      style="margin-top: 4px; border-radius: 0.7rem"
                                      class="white px-4 py-1"
                                      v-for="(item, index) in selectedTemplate?.frontend?.buttons"
                                      :key="
                                        index + selectedTemplate?.frontend?.body?.parameters.length
                                      "
                                    >
                                      <div
                                        style="font-weight: 600; max-width: calc(100% - 40px)"
                                        md="12"
                                        class="font-level-2-bold cyan--text d-flex justify-center mx-1"
                                      >
                                        {{ item.text }}
                                      </div>
                                    </div>
                                  </template>
                                </div>
                                <div
                                  style="position: absolute; bottom: 0; z-index: 9999"
                                  class="mr-2"
                                >
                                  <div class="d-flex justify-space-between align-center">
                                    <div class="d-flex align-center mr-2 mb-2 custom-input">
                                      <v-text-field
                                        color="grey"
                                        prepend-inner-icon="mdi-emoticon-excited-outline"
                                        append-icon="mdi-camera"
                                        hide-details
                                        label="Type a Message"
                                        solo
                                        dense
                                      ></v-text-field>
                                      <!-- <v-icon color="grey lighten-1">mdi-emoticon-excited-outline</v-icon>
                                <div class="ml-3 grey--text">Type a Message</div>
                              </div>
                              <v-icon color="grey darken-1">mdi-camera</v-icon> -->
                                    </div>
                                    <div
                                      class="d-flex justify-center align-center p-2 mb-2 rounded-xl"
                                      style="background: #095e54"
                                    >
                                      <v-icon size="20" color="white">mdi-microphone</v-icon>
                                    </div>
                                  </div>
                                  <!-- </div> -->
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="start-chat" v-else>
                            <div
                              pattern="https://elfsight.com/assets/chats/patterns/whatsapp.png"
                              class="WhatsappChat__Component-sc-1wqac52-0 whatsapp-chat-body notification-screen"
                            >
                              <div class="">
                                <v-expansion-panels>
                                  <v-expansion-panel style="border-radius: 10px">
                                    <v-expansion-panel-header class="font-level-5-bold px-3">
                                      <span>
                                        <v-icon color="red"> mdi-bell-outline </v-icon> Title
                                        Notification</span
                                      >
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                      Some content
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>
                                </v-expansion-panels>
                              </div>
                              <div
                                v-if="false"
                                class="d-flex flex-column justify-space-between"
                                style="height: 100%"
                              >
                                <div class="WhatsappChat__MessageContainer-sc-1wqac52-1 dAbFpq">
                                  <div
                                    style="opacity: 1; margin-left: 1px"
                                    class="WhatsappChat__Message-sc-1wqac52-4 kAZgZq"
                                  >
                                    <div class="WhatsappChat__Text-sc-1wqac52-2 iSpIQi">
                                      {{ matchingHeader() }}
                                    </div>
                                    <div
                                      class="font-level-1-bold"
                                      v-if="
                                        header_image_url &&
                                        this.selectedTemplate.header_type == 'image'
                                      "
                                    >
                                      <v-img
                                        v-if="
                                          header_image_url &&
                                          this.selectedTemplate.header_type == 'image'
                                        "
                                        style="height: 150px; width: 100%; border-radius: 0.2rem"
                                        :src="header_image_url"
                                      ></v-img>
                                    </div>
                                    <span style="display: none"> {{ header_image_url }}</span>

                                    <div
                                      class="font-level-1-bold grey lighten-4 p-2"
                                      style="font-weight: 600; border-radius: 0.2rem"
                                      v-if="
                                        header_image_url &&
                                        this.selectedTemplate.header_type == 'document'
                                      "
                                    >
                                      <v-icon color="red">mdi-file-pdf-box</v-icon>
                                      <span style="font-weight: 500" class="font-level-2-bold ml-1">
                                        {{ template.file[0].name }}
                                      </span>
                                    </div>
                                    <div
                                      class="font-level-1-bold"
                                      v-if="header_image_url && selectedTemplate?.is_file_header"
                                    >
                                      <v-img
                                        v-if="header_image_url"
                                        style="height: 150px; width: 100%; border-radius: 0.2rem"
                                        :src="header_image_url"
                                      ></v-img>
                                    </div>
                                    <div class="mt-1">
                                      {{ matchingBody() }}
                                    </div>
                                    <div class="mt-2">
                                      {{ selectedTemplate?.frontend?.footer }}
                                    </div>

                                    <div class="WhatsappChat__Time-sc-1wqac52-5 cqCDVm">Now</div>
                                  </div>
                                  <template>
                                    <div
                                      style="margin-top: 4px; border-radius: 0.7rem"
                                      class="white px-4 py-1"
                                      v-for="(item, index) in selectedTemplate?.frontend?.buttons"
                                      :key="
                                        index + selectedTemplate?.frontend?.body?.parameters.length
                                      "
                                    >
                                      <div
                                        style="font-weight: 600; max-width: calc(100% - 40px)"
                                        md="12"
                                        class="font-level-2-bold cyan--text d-flex justify-center mx-1"
                                      >
                                        {{ item.text }}
                                      </div>
                                    </div>
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </MobileFrame>
                </v-flex>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
      <Dialog :dialog.sync="testDialog" :dialogWidth="750">
        <template v-slot:title> Enter Phone Number</template>
        <template v-slot:body>
          <v-row class="delete-dialog">
            <v-col md="3" class="my-auto py-0">
              <label for="name" class="btx-label mt-2">Phone Number </label>
            </v-col>
            <v-col md="9" class="py-0">
              <PhoneInput
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                id="name"
                v-model="testPhoneNumber"
                placeholder="Phone Number"
              ></PhoneInput>
            </v-col>
          </v-row>
        </template>
        <template v-slot:action>
          <v-btn
            class="white--text"
            :loading="pageLoading"
            :disabled="pageLoading"
            depressed
            color="red lighten-1"
            tile
            v-on:click="testBroadcast()"
          >
            Test
          </v-btn>
          <v-btn depressed tile :disabled="pageLoading" v-on:click="testDialog = false">
            No, Close
          </v-btn>
        </template>
      </Dialog>
    </v-row>
    <CreateSegment
      v-if="generateSegmentDialog"
      :dialog="generateSegmentDialog"
      @close="generateSegmentDialog = false"
      @success="getBroadcastOption()"
    />
  </v-sheet>
</template>

<script>
import TextInput from "@/view/components/TextInput";
import PhoneInput from "@/view/components/Phone";
import FileUpload from "@/view/components/FileUpload";
import NumberInput from "@/view/components/NumberInput";
import SelectInput from "@/view/components/SelectInput";
import DatePicker from "@/view/components/DatePicker";
import Dialog from "@/view/components/Dialog";
import CreateSegment from "@/view/module/marketing/CreateSegment";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import MobileFrame from "@/view/module/marketing/MobileFrame.vue";
import MomentJS from "moment-timezone";
import {
  GET_BROADCAST_OPTIONS,
  BlAST_BROADCAST,
  CREATE_BROADCAST,
  GET_BROADCAST,
  TEST_BROADCAST,
  UPLOAD_TEMPLATE_FILE,
} from "@/core/lib/marketing.lib";
import { cloneDeep } from "lodash";
import { mapGetters } from "vuex";
export default {
  name: "customer-create",
  title: "Create Customer",
  data() {
    return {
      panel: [0],
      pageLoading: false,
      header_image_url: null,
      generateSegmentDialog: false,
      testPhoneNumber: null,
      formValid: true,
      customerId: null,
      isBlastBroadcast: false,
      broadcast: {
        name: null,
        type: "whatsapp",
        header_image: [],
        segment: null,
        category: "promotion",
        selectedTemplate: null,
        message: null,
        schedule_type: "now",
        // reminder_type_value: null,
        recurrence_type: 1,
        recurrence_start_date: MomentJS().format("YYYY-MM-DD"),
        recurrence_end_date: null,
        frequency: null,
      },
      newButtons: null,
      segmentList: [],
      testDialog: false,
      categoryList: [],
      filterTemplateLists: [],
      checkbox1: false,
      templateList: [],
      menu2: false,
      imageKey: 1,
      modal2: false,
      selectedTemplate: null,
      segmentOption: {},
      remindertypeList: [],
      newTemplate: {
        header: null,
        body: null,
        footer: null,
      },
    };
  },
  methods: {
    inputFileImage() {
      this.imageKey = this.imageKey + 1;
      this.getHeaderHandler();
    },
    fileRemove() {
      this.header_image_url = null;
    },
    async getHeaderHandler() {
      if (this.broadcast.header_image[0]?.base64) {
        const _this = this;
        this.pageLoading = true;
        const files = {
          files: [
            {
              name: this.broadcast.header_image[0].name,
              suffix: this.broadcast.header_image[0].suffix,
              base64: this.broadcast.header_image[0].base64,
            },
          ],
        };
        try {
          const data = await UPLOAD_TEMPLATE_FILE(files);
          this.header_image_url = data;
        } catch (error) {
          _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
          this.pageLoading = false;
          return;
        } finally {
          this.pageLoading = false;
        }
      }
    },
    frequencyChange() {
      let start_date = MomentJS(this.broadcast.recurrence_start_date);
      if (this.broadcast.recurrence_type === 1) {
        this.broadcast.recurrence_end_date = null;
      } else if (this.broadcast.recurrence_type === 2) {
        const days = start_date.add(this.broadcast.frequency, "days");
        this.broadcast.recurrence_end_date = days.format("YYYY-MM-DD");
      } else if (this.broadcast.recurrence_type === 3) {
        const days = start_date.add(this.broadcast.frequency * 7, "days");
        this.broadcast.recurrence_end_date = days.format("YYYY-MM-DD");
      } else if (this.broadcast.recurrence_type === 4) {
        const days = start_date.add(this.broadcast.frequency, "months");
        this.broadcast.recurrence_end_date = days.format("YYYY-MM-DD");
      } else if (this.broadcast.recurrence_type === 5) {
        const days = start_date.add(this.broadcast.frequency * 3, "months");
        this.broadcast.recurrence_end_date = days.format("YYYY-MM-DD");
      } else if (this.broadcast.recurrence_type === 6) {
        const days = start_date.add(this.broadcast.frequency * 6, "months");
        this.broadcast.recurrence_end_date = days.format("YYYY-MM-DD");
      }
    },
    reminderTypeChange() {
      let start_date = MomentJS(this.broadcast.recurrence_start_date);
      this.broadcast.frequency = 1;
      if (this.broadcast.recurrence_type === 1) {
        this.broadcast.frequency = null;
        this.broadcast.recurrence_end_date = null;
      } else if (this.broadcast.recurrence_type === 2) {
        this.broadcast.frequency = 1;
        const days = start_date.add(this.broadcast.frequency, "days");
        this.broadcast.recurrence_end_date = days.format("YYYY-MM-DD");
      } else if (this.broadcast.recurrence_type === 3) {
        // this.broadcast.frequency = 1;
        const days = start_date.add(this.broadcast.frequency * 7, "days");
        this.broadcast.recurrence_end_date = days.format("YYYY-MM-DD");
      } else if (this.broadcast.recurrence_type === 4) {
        const days = start_date.add(this.broadcast.frequency, "months");
        this.broadcast.recurrence_end_date = days.format("YYYY-MM-DD");
        // this.broadcast.frequency = 1;
      } else if (this.broadcast.recurrence_type === 5) {
        const days = start_date.add(this.broadcast.frequency * 3, "months");
        this.broadcast.recurrence_end_date = days.format("YYYY-MM-DD");
        // this.broadcast.frequency = 1;
      } else if (this.broadcast.recurrence_type === 6) {
        const days = start_date.add(this.broadcast.frequency * 6, "months");
        this.broadcast.recurrence_end_date = days.format("YYYY-MM-DD");
        // this.task.frequency = 1;
      }
    },
    async blastBroadcast() {
      const _this = this;
      this.pageLoading = true;
      this.isBlastBroadcast = true;
      try {
        if (this.$route.name == "create-broadcast") {
          await this.saveBroadcast();
        }
        await BlAST_BROADCAST(this.broadcast_id);

        _this.$store.commit(SET_MESSAGE, [
          { model: true, message: "Broadcast Blast Successfully." },
        ]);
        this.$router.push({
          name: "broadcast",
          query: { t: new Date().getTime() },
        });
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    async saveBroadcast() {
      this.pageLoading = false;
      const _this = this;
      if (this.validateFormInternal()) {
        if (this.broadcast.header_image[0]?.base64) {
          const _this = this;
          this.pageLoading = true;
          const files = {
            files: [
              {
                name: this.broadcast.header_image[0].name,
                suffix: this.broadcast.header_image[0].suffix,
                base64: this.broadcast.header_image[0].base64,
              },
            ],
          };
          try {
            const data = await UPLOAD_TEMPLATE_FILE(files);
            this.broadcast.header_file_url = data;
            console.log("this.broadcast.header_file_url", this.broadcast.header_file_url);
          } catch (error) {
            _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
            this.pageLoading = false;
            return;
          } finally {
            this.pageLoading = false;
          }
        }
        let headerString = [];
        if (cloneDeep(_this.selectedTemplate?.frontend?.header?.parameters)) {
          _this.selectedTemplate?.frontend?.header?.parameters.forEach((ele) => {
            headerString.push({ value: ele.value });
          });
        }
        let bodyString = [];
        if (cloneDeep(_this.selectedTemplate?.frontend?.body?.parameters)) {
          _this.selectedTemplate?.frontend?.body?.parameters.forEach((ele) => {
            bodyString.push({ value: ele.value });
          });
        }
        const buttons = cloneDeep(_this.selectedTemplate?.frontend?.buttons);

        let ButtonTextValue = [];
        buttons.forEach((ele) => {
          let stringArray = [];
          if (ele?.parameters.length)
            ele.parameters.forEach((para) => {
              stringArray.push(para.value);
            });
          ButtonTextValue.push({ value: stringArray });
          stringArray = [];
        });

        let body = {
          id: this.$route.name === "create-broadcast" ? null : this.broadcast_id,
          name: _this.broadcast.name,
          broadcast_types: _this.broadcast.type,
          segment: _this.broadcast.segment,
          template: _this.selectedTemplate.id,
          header_image: this.broadcast.header_file_url,
          header_text_values: headerString,
          body_text_values: bodyString,
          button_text_values: ButtonTextValue,
          footer_text_values: null,
          category: "birthday",
          schedule_type: _this.broadcast.schedule_type,
        };
        if (_this.broadcast.schedule_type == "later") {
          const data = {
            schedule_datetime: _this.broadcast.schedule_date + " " + _this.broadcast.time,
          };
          body = { ...body, ...data };
        }
        if (_this.broadcast.category == "promotion") {
          const data = {
            recurrence_type: this.broadcast.recurrence_type,
            frequency: this.broadcast.frequency,
            recurrence_start_date: this.broadcast.recurrence_start_date,
            recurrence_end_date: this.broadcast.recurrence_end_date,
          };
          body = { ...body, ...data };
        }
        if (_this.broadcast.category == "birthday") {
          const data = {
            days_before: _this.broadcast.day_before,
          };
          body = { ...body, ...data };
        }
        try {
          const { id } = await CREATE_BROADCAST(body);
          console.log("id", id);
          this.broadcast_id = id;
          if (_this.$route.params.id) {
            if (this.$route.name == "update-broadcast") {
              _this.$store.commit(SET_MESSAGE, [
                { model: true, message: "Broadcast Updated Successfully." },
              ]);
            } else {
              _this.$store.commit(SET_MESSAGE, [
                { model: true, message: "Broadcast Created Successfully." },
              ]);
            }
          }
          if (!this.isBlastBroadcast) {
            this.$router.push({
              name: "broadcast",
              query: { t: new Date().getTime() },
            });
          }
        } catch (error) {
          _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        } finally {
          _this.pageLoading = false;
        }
      }
    },
    validateFormInternal() {
      const formErrors = this.validateForm(this.$refs.broadcastForm);
      if (formErrors.length) {
        this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
        return false;
      }
      if (!this.$refs.broadcastForm.validate()) {
        return false;
      }
      return true;
    },
    async confirmTestBroadcast() {
      if (this.validateFormInternal()) {
        this.testDialog = true;
      }
    },
    async testBroadcast() {
      if (this.validateFormInternal()) {
        const _this = this;
        let headerString = [];
        if (cloneDeep(_this.selectedTemplate?.frontend?.header?.parameters)) {
          _this.selectedTemplate?.frontend?.header?.parameters.forEach((ele) => {
            headerString.push({ value: ele.value });
          });
        }
        let bodyString = [];
        if (cloneDeep(_this.selectedTemplate?.frontend?.body?.parameters)) {
          _this.selectedTemplate?.frontend?.body?.parameters.forEach((ele) => {
            bodyString.push({ value: ele.value });
          });
        }

        const buttons = cloneDeep(_this.selectedTemplate?.frontend?.buttons);
        let ButtonTextValue = [];
        if (buttons && buttons.length) {
          buttons.forEach((ele) => {
            let stringArray = [];
            if (ele?.parameters.length)
              ele.parameters.forEach((para) => {
                stringArray.push(para.value);
              });
            ButtonTextValue.push({ value: stringArray });
            stringArray = [];
          });
        }
        const broadcast = this.broadcast;
        const body = {
          to: this.testPhoneNumber,
          broadcast_types: broadcast.type,
          segment: broadcast.segment,
          template: this.selectedTemplate.id,
          header_image: this.header_image_url,
          header_text_values: headerString,
          body_text_values: bodyString,
          button_text_values: ButtonTextValue,
        };
        try {
          await TEST_BROADCAST(body);
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Broadcast Tested Successfully." },
          ]);
          this.$router.push({
            name: "broadcast",
            query: { t: new Date().getTime() },
          });
          this.testDialog = false;
        } catch (error) {
          _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        } finally {
          this.pageLoading = false;
        }
      }
    },
    async getBroadcastOption() {
      const _this = this;
      this.pageLoading = true;
      try {
        const data = await GET_BROADCAST_OPTIONS();

        this.segmentOption = data;
        this.segmentList = data.segments;
        this.categoryList = data.template_category;
        this.selectedTemplate = data.templates[0];
        this.remindertypeList = data.recurrence_types;
        if (this.$route.name == "create-broadcast") {
          this.broadcast.selectedTemplate = 0;
        }
        this.templateList = data.templates;
        this.filterTemplateLists = data.templates;
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    templateChange(template) {
      console.log("template", template);
      this.broadcast.header_image = [];
      this.header_image_url = null;
      // const template1 = this.segmentOption ? cloneDeep(this.segmentOption.templates) : [];

      // template1.forEach((element) => {
      //   if (element.template_name == template) {
      //     console.log("template1",template1)
      //     console.log("element",element)
      //     this.selectedTemplate = element;
      //     console.log("  this.selectedTemplate ", this.selectedTemplate);
      //   }
      // });
    },
    async getBroadcast() {
      const _this = this;
      try {
        const broadcast = await GET_BROADCAST(this.$route.params?.id);
        const templateList = cloneDeep(this.templateList);
        console.log(broadcast.template_name);

        templateList.forEach((ele, index) => {
          if (ele.template_name == broadcast.template_name) {
            console.log("index", index);
            this.selectedTemplate = index;
          }
        });
        this.broadcast.name = broadcast.name;
        this.broadcast.day_before = broadcast.days_before;
        this.broadcast.segment = broadcast.segment;
        this.broadcast.schedule_type = broadcast.schedule_type;
        this.header_image_url = broadcast?.header_image;
        this.broadcast.type = broadcast?.broadcast_types.split(",");
        this.broadcast.recurrence_type = broadcast?.recurrence_type;
        this.broadcast.category = broadcast.category;
        this.broadcast.frequency = broadcast?.frequency;
        this.broadcast.recurrence_start_date = broadcast?.recurrence_start_date;
        this.broadcast.recurrence_end_date = broadcast?.recurrence_end_date;
        // this.broadcast.schedule_date=broadcast?.schedule_date
        if (broadcast?.schedule_datetime) {
          const date = broadcast?.schedule_datetime.split(" ");
          this.broadcast.schedule_date = date[0];
          this.broadcast.time = date[1];
        }

        this.templateChange(broadcast.template_name);
        this.selectedTemplate.frontend.header.parameters = broadcast.header_text_values;
        // const body = cloneDeep(this.selectedTemplate?.frontend?.body?.parameters);
        // console.log(broadcast.body_text_values);
        // const bodyValue = broadcast.body_text_values;
        // const newBody = body.map((ele, index) => {
        //   ele.value = bodyValue[index].value;
        //   return ele;
        // });
        this.selectedTemplate.frontend.body.parameters = broadcast.body_text_values;
        const buttons = cloneDeep(this.selectedTemplate?.frontend?.buttons);
        // console.log(buttons);
        const b = buttons.map((ele, parentIndex) => {
          if (ele.url) {
            ele.parameters.forEach((ele1, index) => {
              ele1.value = broadcast.button_text_values[parentIndex].value[index];
            });
          }
          return ele;
        });
        this.selectedTemplate.frontend.buttons = b;

        this.filterTemplateList();
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      }
    },
    filterTemplateList() {
      const lists = cloneDeep(this.templateList);
      if (lists.length) {
        let templates = lists.filter((list) => {
          // console.log(list);
          if (
            list?.template_type == this.broadcast.type &&
            list?.template_category == this.broadcast.category
          ) {
            return list;
          }
        });
        this.filterTemplateLists = templates;
        if (this.$route.name == "create-broadcast") {
          this.selectedTemplate = this.filterTemplateLists[0];
        }
      }
    },
  },
  computed: {
    ...mapGetters(["errors"]),
    title() {
      if (this.$route.name == "update-broadcast") {
        return "Update Broadcast";
      } else {
        return "Create Broadcast";
      }
    },
    dynamicButtonUrl() {
      return (index) => {
        let url = cloneDeep(this.selectedTemplate?.frontend?.buttons[index]?.url);
        const parameters = cloneDeep(this.selectedTemplate?.frontend?.buttons[index]?.parameters);
        // console.log("buttons parameters", parameters);
        if (url) {
          for (let index = 0; index < parameters.length; index++) {
            const element = parameters[index];
            if (element.value) {
              const count = index + 1;
              url = url.replaceAll("{{" + count + "}}", element.value);
            }
          }
        }
        return url;
      };
    },
    matchingBody() {
      return () => {
        if (this.selectedTemplate) {
          let string1 = this.selectedTemplate?.frontend?.body?.string;
          if (this.selectedTemplate?.frontend?.body?.parameters) {
            for (
              let index = 0;
              index < this.selectedTemplate?.frontend?.body?.parameters.length;
              index++
            ) {
              const item = this.selectedTemplate?.frontend?.body?.parameters[index];
              if (item.value) {
                const count = index + 1;
                string1 = string1.replaceAll("{{" + count + "}}", item.value);
              }
            }
            return string1;
          }
        }
      };
    },
    matchingHeader() {
      return () => {
        if (this.selectedTemplate) {
          let string1 = cloneDeep(this.selectedTemplate?.frontend?.header?.string);
          if (this.selectedTemplate?.frontend?.header?.parameters) {
            const headerParameter = cloneDeep(this.selectedTemplate?.frontend?.header?.parameters);
            for (let index = 0; index < headerParameter.length; index++) {
              const item = headerParameter[index];
              if (item.value) {
                const count = index + 1;
                string1 = string1.replaceAll("{{" + count + "}}", item.value);
              }
            }
            return string1;
          }
        }
      };
    },
  },
  components: {
    TextInput,
    SelectInput,
    FileUpload,
    // TextAreaInput,
    CreateSegment,
    Dialog,
    DatePicker,
    MobileFrame,
    NumberInput,
    PhoneInput,
  },

  async mounted() {
    await this.getBroadcastOption();
    if (this.$route.name == "update-broadcast") {
      await this.getBroadcast();
    }
    if (this.$route.name == "create-broadcast") {
      this.broadcast.selectedTemplate = 0;
      await this.filterTemplateList();
    }

    if (this.$route.name == "update-broadcast") {
      this.broadcast_id = this.$route.params.id;
    }
  },
};
</script>
<style scoped>
.smartphone {
  max-width: 390px;
  width: 100%;
}
.whatsapp-chat-body.notification-screen::before {
  background-image: none;
  background-color: antiquewhite;
}
</style>
